<template>
  <div class="manage-suppliers">
      <div class="suppliers">
          <div class="search">
            <el-input v-model="search" placeholder="חפש סניף">
                <template #prefix>
                    <i class="el-input__icon el-icon-search"></i>
                </template>
            </el-input>
          </div>
          <div class="add-supplier">
              <el-button @click="handle_add_new_supplier" type="success" icon="el-icon-plus" circle></el-button>
          </div>
        <template v-for="(supplier,index) in filter_by_search" :key="index">
            <div class="supplier">
                <Supplier @modify="modify_supplier($event)" @delete="delete_supplier($event)" :supplier_name="supplier" />
            </div>
        </template>
         
      </div>
  </div>
</template>

<script>
import { ref } from '@vue/reactivity'
import Supplier from '../../../components/Admin/Shivuk_Rosman/suppliers/Supplier.vue'
import { computed, onMounted } from '@vue/runtime-core'
import {get_suppliers_from_db,add_new_supplier_to_db} from '../../../Methods/rosman_shivuk_funcs'
import Swal from 'sweetalert2'
export default {
    components:{Supplier},
    setup(){
        const search = ref('')
        
        const suppliers = ref([])

        const handle_add_new_supplier = ()=>{
            Swal.fire({
                icon: 'question',
                title: 'שם הספק החדש',
                input: 'text',
                confirmButtonText: 'עדכן',
                cancelButtonText: 'בטל',
                showCancelButton: true,
                denyButtonText: 'בטל עדכון',
                showDenyButton: true
            }).then(async res=>{
                if(res.isConfirmed && res.value){
                    await add_new_supplier_to_db(res.value)
                    suppliers.value.push(res.value)
                }
            })
        }

        const delete_supplier=(supplier_name)=>{
            const index = suppliers.value.findIndex(supplier=>supplier==supplier_name)
            if(index!=-1){
                suppliers.value.splice(index,1)
            }
        }
        const modify_supplier=(data)=>{
            const index = suppliers.value.findIndex(supplier=>supplier==data.old_supplier_name)
            console.log('index:',index);
            if(index!=-1){
                suppliers.value[index]=data.new_supplier_name
            }
        }

        const filter_by_search = ref(computed(()=>{
            if(search.value=='') return suppliers.value
            return suppliers.value.filter(supplier=>{
                if(supplier.includes(search.value)){
                    return supplier
                }
            })
            
        }))
        
        onMounted(async()=>{
           suppliers.value =  await get_suppliers_from_db()
        })
       
       
        return{modify_supplier,delete_supplier,handle_add_new_supplier,search,filter_by_search,suppliers}
    }
}
</script>

<style scoped>
    .manage-suppliers{
        width: 100%;
        height: 100%;
        flex-direction: column;
        display: flex;
        align-items: center;
    }
    .suppliers{
        width: 375px;
        height: 100%;
        margin-bottom: 5px;
        text-align: center;
        overflow:hidden;
        overflow-y: auto;
    }
    .supplier{
        margin-bottom: 5px;
        width: 100%;
        height: 50px;
    }
    .add-supplier{
        width: 100%;
        height: 45px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .search{
        width: 100%;
        height: 40px;
    }
</style>